import React, { useEffect, useState } from 'react';
import styles from './Faqs.module.scss';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import MaterialField from '../../components/form/MaterialField/MaterialField';
import { Formik, useFormikContext } from 'formik';
import { FAQS_DATA } from './FaqsData';
import FaqCard from '../../components/FaqCard/FaqCard';

const createArrayByWord = (word: string) => {
  if (!word) {
    return [];
  }

  const result: any = [];
  let wordArray = word.split(' ');

  wordArray.forEach((element) => {
    result.push(element);
  });

  return result;
};

const FormObserver = ({ setFilters }: any) => {
  const { values }: any = useFormikContext();

  useEffect(() => {
    setFilters(createArrayByWord(values?.search));
  }, [values]);

  return null;
};


const Faqs = () => {
  const [filters, setFilters] = useState([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [isMobile, setIsMobile] = useState(false);

  // replace accent
  const formatString = (str: string) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase();
  }

  const dataContainsAllFilter = (data: any[], filters: any[]) => {
    if (filters.length === 0) return data;
    // filter data[item]?.tags
    const filteredTags = data.filter((item) => {
      return filters.every((filter) => {

        // Create array by word
        const tags = item.tags.map((tag: string) => {
          return createArrayByWord(formatString(tag));
        }).flat();

        // Convert tags to string separated by space
        const tagsString = tags.join(' ');


        // Find filter in tags, question or answer
        return tagsString.includes(formatString(filter)) || formatString(item.question).includes(formatString(filter)) || formatString(item.answer).includes(formatString(filter));
      });
    });

    return filteredTags;
  };

  const isOdd = (num: number) => num % 2;

  useEffect(() => {
    // is mobile?
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    setFilteredData(dataContainsAllFilter(FAQS_DATA, filters));
  }, [filters]);

  return (
    <div className='container'>
      <Breadcrumbs />

      <div className='mb-5'>
        <h1 className={styles.title}>Preguntas frecuentes</h1>
        <Formik
          initialValues={{
            search: ''
          }}
          onSubmit={() => { }}
          validateOnChange={true}
        >
          {(formik) => (
            <>
              <FormObserver setFilters={setFilters} />
              <MaterialField
                className={styles.search}
                placeholder="¿Necesitas ayuda?"
                name="search"
                type="text"
              />
            </>
          )}
        </Formik>
      </div>


      <div className={`${styles.cardsContainer}`}>
        <div className={styles.col}>
          {filteredData?.map((faq: any, index) => {
            if (!isOdd(index)) {
              return <FaqCard key={index} question={faq.question} answer={faq.answer} />
            }
          })}
        </div>
        <div className={styles.col}>
          {filteredData?.map((faq: any, index) => {
            if (isOdd(index)) {
              return <FaqCard key={index} question={faq.question} answer={faq.answer} />
            }
          })}
        </div>
      </div>
    </div >
  );
}

export default Faqs;