import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyles, titleStyles } from './styles';
import Button from '../Button';
import QuestionsAndAnswersModal from '../QuestionsAndAnswersModal/QuestionsAndAnswersModal';
import CustomSkeleton from '../CustomSkeleton';
import qAndAImage from '../../assets/img/actividades-a-financiar.png';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import VideoPlaybackModal from '../VideoPlaybackModal/VideoPlaybackModal';
import { benevitsAPI } from '../../api/modules/benevits';
import MoreInformationModal from '../VideoPlaybackModal/components/MoreInformationModal/MoreInformationModal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import WithoutBenevits from '../WithoutBenevits/WithoutBenevits';
import { Title } from '../../assets/styles/Typography';
import { Section } from '../../assets/styles/Layout';
import { actions } from '../../store/modules/session';
import ItemCardB from '../ItemCardB';

type Props = {
	benevits: Object[];
	title?: string;
	sizeTitle?: number;
	isLoadingBenevits: boolean;
	limit?: number;
	alignTitle?:
	| 'start'
	| 'end'
	| 'left'
	| 'right'
	| 'center'
	| 'justify'
	| 'match-parent';
};

export default function BenevitsB({
	benevits,
	isLoadingBenevits,
	title,
	alignTitle = 'center',
	sizeTitle = 25,
	...props
}: Props) {
	const dispatch = useDispatch();
	const [limit, setLimit] = useState<number>(props.limit ?? 20);
	const [activeBenevit, setActiveBenevit] = useState<any>({});
	const [slideState, setSlideState] = useState<any>({});
	const [campaingModal, setCampaingModal] = useState<boolean>(false);
	const [campaingModalVideo, setCampaingModalVideo] = useState<boolean>(false);
	const [campaingType, setCampaingType] = useState<number>(0);
	const [campaing, setCampaing] = useState<any>();
	const [openMoreInfoModal, setOpenMoreInfoModal] = useState<boolean>(false);
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const titleClasses = titleStyles({ alignTitle, sizeTitle });
	const navigate = useNavigate();
	const [data, setData] = useState(benevits.slice(0, limit));
	const token: string | undefined = user?.token;
	const [openModalMessage, setOpenModalMessage] = useState<boolean>(false);
	const [openModalCP, setOpenModalCP] = useState<boolean>(false);
	const [zipCode, setZipCode] = useState<string | undefined>();
	const [email, setEmail] = useState<string>('');
	const [openRegister, setOpenRegister] = useState<boolean>(false);
	const [isMobile, setIsMobile] = useState(false);
	const [isScrollLoading, setIsScrollLoading] = useState(false);
	const isHomePage = (window.location.pathname === '/' || window.location.pathname.includes('/info-benevit/'));

	const onCloseModalCampaing = (): void => setCampaingModal(false);

	const handleSetBenevit = (benevit: any) => {
		if (!user) {
			dispatch(actions.setIsOpenLogin(true));
			navigate(`/info-benevit/${benevit.slug}`);
			return;
		}

		setActiveBenevit(benevit);

		if (!benevit.unlocked && benevit.id != 1000) {
			navigate(`/info-benevit/${benevit.slug}`);
		} else if (!benevit.unlocked && benevit.id === 1000) {
			setOpenModalCP(!openModalCP)
		} else {
			navigate(`/info-benevit/${benevit.slug}`);
		}
	};

	const handleGoToBenevit = () => {
		setOpenMoreInfoModal(false);
		navigate(`/info-benevit/${activeBenevit.slug}`);
	};

	const handleUnlockBenevit = async (answers?: any[], slide?: any) => {
		await benevitsAPI.unlockBenevit(activeBenevit.id, token);

		if (campaingModalVideo) {
			await benevitsAPI.updateMetrics(campaing?.metrics?.id as number, token);
			setCampaingModalVideo(false);
		} else {
			await benevitsAPI.updateAnsweredMetrics(
				campaing?.metrics?.id as number,
				token
			);
			setCampaingModal(!campaingModal);
			if ((answers?.length ?? 0) > 0) {
				/* aplicar logica para guardar las respuestas */
				answers?.forEach((answer, index) => {
					benevitsAPI.postAnswers(
						campaing?.questions[index].id as number,
						answer.option_value,
						token
					);
				});
			}
		}
		handleGoToBenevit();
	};

	const handleMoreInfo = () => {
		setOpenMoreInfoModal(true);
	};

	const handleCloseCP = () => {
		setOpenModalCP(false);
	};

	const handleCloseMessage = () => {
		setOpenModalMessage(false);
		benevits.filter(b => {
			if (b['id'] === 1000) {
				b['unlocked'] = true;
			}
		})
		navigate(`/info-benevit/${activeBenevit.slug}`);
	};

	const handleAnswer = async () => {
		await benevitsAPI.unlockBenevit(1000, token);
		await benevitsAPI.postAnswers(
			1000 as number,
			zipCode,
			token
		);

		if ("geolocation" in navigator) {
			console.log("Available");
			navigator.geolocation.getCurrentPosition(function (position) {
				benevitsAPI.location(position.coords.latitude, position.coords.longitude, token);
			});
		} else {
			console.log("Not Available");
		}
		setOpenModalCP(false);
		setOpenModalMessage(true);
		setZipCode('');
	};

	const _renderItems = (items) => items.map((item, index) => (
		<div
			key={index}
			className="d-flex col-lg-3 col-md-4 col-sm-6 col-xs-12"
		>
			<ItemCardB
				data={item}
				handleSetBenevit={handleSetBenevit}
			/>
		</div>
	));

	const handleLoadMore = () => {
		// If there isn't a token then show de login modal
		if ((!token || token === undefined) && isHomePage) {
			if (((window.innerHeight + document.documentElement.scrollTop) >= document.documentElement.offsetHeight)) {
				dispatch(actions.setIsOpenLogin(true));
			}
			return;
		}

		if ((window.innerHeight + document.documentElement.scrollTop) >= document.documentElement.offsetHeight - 300) {
			if (limit !== limit + 20) {
				setIsScrollLoading(true);
				setLimit((lim) => lim + 20);
				setIsScrollLoading(false);
			}
		}
	}

	// On mount
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 768) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	// Scroll listener
	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			window.removeEventListener('scroll', handleLoadMore);
			window.addEventListener('scroll', handleLoadMore);
		}

		return () => {
			isMounted = false;
			window.removeEventListener('scroll', handleLoadMore);
		}
	}, [token]);

	// Update data
	useEffect(() => {
		setData(benevits.slice(0, limit));
	}, [limit, benevits]);

	return (
		<>
			<Section>
				{!isLoadingBenevits ? (
					<>
						{!data.length ? (<>
							<WithoutBenevits />
						</>
						) : (
							<>
								{title &&
									<div className="row">
										<div className="col-12">
											<div className='d-flex flex-row align-items-center gap-1'>
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width={32} height={32}><path d="M6.38231 5.9681C7.92199 4.73647 9.87499 4 12 4C14.125 4 16.078 4.73647 17.6177 5.9681L19.0711 4.51472L20.4853 5.92893L19.0319 7.38231C20.2635 8.92199 21 10.875 21 13C21 17.9706 16.9706 22 12 22C7.02944 22 3 17.9706 3 13C3 10.875 3.73647 8.92199 4.9681 7.38231L3.51472 5.92893L4.92893 4.51472L6.38231 5.9681ZM12 20C15.866 20 19 16.866 19 13C19 9.13401 15.866 6 12 6C8.13401 6 5 9.13401 5 13C5 16.866 8.13401 20 12 20ZM13 12H16L11 18.5V14H8L13 7.4952V12ZM8 1H16V3H8V1Z"></path></svg>
												<Title className="m-0">{title}</Title>
											</div>
										</div>
									</div>
								}

								<div className="row row-gap-3">
									{_renderItems(data)}

									{/* { // Load more button
										limit < benevits.length && (
											<div className='d-flex col-12 justify-content-center'>
												<Button variant='outline' onClick={() => setLimit(limit + 20)}>
													{isScrollLoading ? 'Cargando...' : 'Cargar más...'}
												</Button>
											</div>
										)} */}

									{(!token && limit < benevits.length && isHomePage) && (
										<div className='d-flex col-12 justify-content-center py-5'>
											<div className='d-flex col-12 justify-content-center py-4 px-4 border rounded'>
												<div className='d-flex flex-column align-items-center gap-4'>
													<strong style={{ maxWidth: '300px' }}>Para conocer más Benevits</strong>
													<Button variant='outline' onClick={() => dispatch(actions.setIsOpenLogin(true))}>
														Ingresar o registrase
													</Button>
												</div>
											</div>
										</div>
									)}
								</div>
							</>
						)}
					</>
				) : (
					<CustomSkeleton />
				)
				}
			</Section >

			{campaingModalVideo && (
				<VideoPlaybackModal
					isMobileVideo={isMobile}
					open={campaingModalVideo}
					url={campaing}
					// url={campaing?.content}
					campaingURL={campaing?.url}
					onCancel={() => setCampaingModalVideo(!campaingModalVideo)}
					handleUnlockBenevit={handleUnlockBenevit}
					light={false}
				// onMoreInfoClick={handleMoreInfo}
				/>
			)
			}
			{
				campaingModal && (
					<QuestionsAndAnswersModal
						open={campaingModal}
						onBackButton={onCloseModalCampaing}
						questionImage={qAndAImage}
						question={campaing}
						handleUnlockBenevit={handleUnlockBenevit}
					/>
				)
			}
			{
				openMoreInfoModal && (
					<MoreInformationModal
						// open={openMoreInfoModal}
						slide={slideState}
						onGoToBenevit={handleGoToBenevit}
					/>
				)
			}
			{
				(
					<Dialog open={openModalCP} onClose={handleCloseCP} maxWidth="xs">
						<DialogTitle>Estás a un paso</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Necesitas verificar tu ubicación. Introduce tu código postal
							</DialogContentText>
							<TextField
								autoFocus
								margin="dense"
								id="cp"
								label="C.P."
								type="text"
								fullWidth
								variant="standard"
								value={zipCode}
								onChange={(newValue) => setZipCode(newValue.target.value)}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleAnswer}>Aceptar</Button>
							<Button onClick={handleCloseCP}>Cancelar</Button>
						</DialogActions>
					</Dialog>
				)
			}
			{
				(
					<Dialog open={openModalMessage} onClose={handleCloseMessage} maxWidth="xs">
						<DialogTitle>¡Gracias por participar!</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Revisa tu correo en los próximos días para ver si eres uno de los afortunados ganadores.
								<br />
								¡No olvides revisar tu spam! A veces, nuestros correos pueden terminar allí por error.
								<br /><br />
								¡Buena suerte!
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleCloseMessage}>Aceptar</Button>
						</DialogActions>
					</Dialog>
				)
			}
		</>
	);
}
