import API from '..';
class CarouselAPI extends API {
	carouselMarcas(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v3/public/brands-all`,
				method: 'GET',
			}),
			true
		);
	}
	banners(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v3/public/headers-banners`,
				method: 'GET',
			}),
			true
		);
	}
}
export const carouselAPI = new CarouselAPI('');
