import API from '..';
class AlliesAPI extends API {
	post(values?: any | undefined | null): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		console.log(values);
		return this.requestWrapper(
			this.request({
				url: `/v3/public/want_ally`,
				method: 'POST',
				headers: {
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
				data: values,
			}),
			true
		);
	}
}

export const alliesAPI = new AlliesAPI('');
