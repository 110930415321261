import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Card,
  Tag,
  Cover,
  CoverImage,
  BrandLogo,
  Content,
  ContentText,
  Title,
  Discount,
  Properties,
  Category,
  CategoryIcon,
  CategoryLabel,
  Availability,
  ExpirationDate,
  Days,
  Actions
} from './styles';
import Button from '../Button';

interface IItemCard {
  handleSetBenevit?: any;
  data: any;
};

const ItemCardB = ({ data, handleSetBenevit }: IItemCard) => {
  const [isMobile, setIsMobile] = useState(false);
  const exp_days = moment(data.expiration_date).diff(moment(), 'days') + 1;
  const getExpirationDate = (days: number) => <ExpirationDate>Vence en <Days>{days}</Days> {days === 1 ? ' día' : 'días'}</ExpirationDate>;

  const renderCategories = (categories: any) => categories.map((category: any, index: number) => (
    <Category key={index}>
      {category?.icon_url && <CategoryIcon src={category?.icon_url} />}
      <CategoryLabel>
        {category?.name === 'Promociones relámpago' ? 'Benevits relámpago' : category?.name}
      </CategoryLabel>
    </Category>
  ));

  const _renderTerritores = (territories: any) => territories.map(
    (territory: any, index: number) => (<Availability key={index}>{territory?.name}</Availability>)
  );

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // On mount
  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Card>
      <Tag>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width={24} height={24}><path d="M6.38231 5.9681C7.92199 4.73647 9.87499 4 12 4C14.125 4 16.078 4.73647 17.6177 5.9681L19.0711 4.51472L20.4853 5.92893L19.0319 7.38231C20.2635 8.92199 21 10.875 21 13C21 17.9706 16.9706 22 12 22C7.02944 22 3 17.9706 3 13C3 10.875 3.73647 8.92199 4.9681 7.38231L3.51472 5.92893L4.92893 4.51472L6.38231 5.9681ZM12 20C15.866 20 19 16.866 19 13C19 9.13401 15.866 6 12 6C8.13401 6 5 9.13401 5 13C5 16.866 8.13401 20 12 20ZM13 12H16L11 18.5V14H8L13 7.4952V12ZM8 1H16V3H8V1Z"></path></svg>
        Se va pronto
      </Tag>

      <Cover background={data?.primary_color}>
        <CoverImage src={data?.vector_full_path} alt="random" size={data?.image_size} />
        <BrandLogo src={data?.ally?.mini_logo_full_path} alt="random" />
      </Cover>

      <Content>
        <ContentText>
          {/* Basic information */}
          <Title>{data.title}</Title>
          {data.id === 1000 && <span>Hasta agotar existencias</span>}

          {data?.discount && <Discount>{data?.discount}</Discount>}

          {/* Properties */}
          <Properties>
            {data?.categories && renderCategories(data?.categories)}

            {/* If territories is lower or equal than 1 show territorie below */}
            {data?.territories?.length <= 1 && _renderTerritores(data?.territories)}
          </Properties>

          {/* If data is higher than 1 show territorie below */}
          {data?.territories?.length > 1 && (
            <Properties>
              {_renderTerritores(data?.territories)}
            </Properties>)
          }

          {/* Expiration date */}
          {/* {getExpirationDate(exp_days)} */}
        </ContentText>

        {/* Actions */}
        <Actions>
          <Button onClick={() => handleSetBenevit(data)} variant="solid" fluid={true} state={data?.unlocked ? 'success' : 'default'} className={`${data?.unlocked && 'clickUnlocketBenevit'}`}>
            {data?.unlocked === true ? data.id === 1000 ? 'Participando' : 'Ver Benevit' : 'Lo quiero'}
          </Button>
        </Actions>
      </Content>
    </Card>);
}

export default ItemCardB;