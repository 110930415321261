import { makeStyles, createStyles } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import backDropImage from '../../assets/img/enmascarar-grupo-35328.png';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modalRoot: {
			top: '114px !important',
			width: '100%',
			height: 'calc(100vh - 114px)',
			bottom: 'initial !important',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			overflow: 'auto',
			backgroundImage: `url(${backDropImage})`,
			backgroundColor: '#FAFAFA',
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			padding: '48px 0',
			overflowY: 'auto',
			[theme.breakpoints.down('lg')]: {
				top: '120px !important',
				height: 'calc(100vh - 120px)',
				backgroundSize: 'cover',
			},
			'& .MuiBackdrop-root': {
				height: 'calc(100vh - 114px)',
				backgroundColor: 'rgba(0, 0, 0, 0)',
				top: '114px !important',
				bottom: 'initial !important',
			},
		},
		modalSubContainer: {
			position: 'relative',
			margin: '20px auto auto auto',
			// padding: '40px',
			width: '100%',
			maxWidth: '500px',
			// maxHeight: '90vh',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			boxShadow: '0px 17px 27px #9D636329 !important',
			borderRadius: '22px !important',
			[theme.breakpoints.down('md')]: {
				// padding: '20px',
				marginLeft: 10,
				marginRight: 10,
			},
			[theme.breakpoints.down('sm')]: {
				maxHeight: 'initial',
			}
		},
		questionImageContainer: {
			marginBottom: 25,
		},
		questionImage: {
			width: '233px',
			height: '233px',
			[theme.breakpoints.down('md')]: {
				width: '184px',
				height: '184px',
			},
		},
		answersGroupHasntBeenAnswered: {
			'&>*': {
				opacity: '0.5 !important',
			},
		},
		answerItem: {
			marginBottom: '26px !important',
			padding: '10px 20px !important',
			width: '100%',
			borderRadius: '9px !important',
			cursor: 'pointer',
			border: '1px solid rgba(0, 0, 0, 0.12) !important',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			[theme.breakpoints.down('md')]: {
				marginBottom: '16px !important',
				width: '100%',
			},
		},
		nonSelectedAnswerItem: {
			backgroundColor: '#F8F8F8 !important',
			border: 'none !important',
		},
		selectedAnswerItem: {
			backgroundColor: '#0126C3 !important',
			'&>p': {
				color: '#FAFAFA !important',
			},
			border: 'none !important',
		},
		correctAnswerItem: {
			backgroundColor: '#6FC152 !important',
			'&>p': {
				color: '#FAFAFA !important',
			},
			opacity: '1 !important',
		},
		correctNotChoosedAnswerItem: {
			backgroundColor: '#6FC152 !important',
			'&>p': {
				color: '#FAFAFA !important',
			},
		},
		incorrectAnswerItem: {
			backgroundColor: '#E61737 !important',
			'&>p': {
				color: '#FAFAFA !important',
			},
			opacity: '1 !important',
		},
		button: {
			height: '54px',
			borderRadius: '14px !important',
			fontSize: '18px !important',
			[theme.breakpoints.down('md')]: {
				height: '41px',
				fontSize: '13px !important',
			},
		},
		continueButton: {
			marginTop: '17px !important',
		},
		cancelButton: {
			marginTop: '25px !important',
			fontSize: '20px !important',
			backgroundColor: 'transparent !important',
		},
		checkIcon: {
			color: '#fff',
		},
		failedIcon: {
			color: '#fff !important',
		},
		input: {
			// outline: '2px solid black',
			borderRadius: '14px',
			// padding: '20px',
			width: '100%',
			height: '100%',
			color: '#080808',
			paddingLeft: '10px',
			fontSize: '16px',
			outline: 'none',
			background: '#F8F8F8',
			border: 'none',
			'&:focus + label span, &:valid + label span': {
				transform: 'translateY(-18px)',
				fontSize: '14px',
			},
			'&:focus ': {
				background: '#FFFFFF',
			},
			'&:valid': {
				background: '#FFFFFF',
			},
			[theme.breakpoints.down('md')]: {
				borderRadius: '9px',
				fontSize: '12px',
				'&:focus + label span, &:valid + label span': {
					transform: 'translateY(-15px)',
					fontSize: '7px',
				},
			},
			[theme.breakpoints.down('sm')]: {
				'&:focus + label span, &:valid + label span': {
					transform: 'translateY(-13px)',
				},
			},
		},
		stepper: {
			flexShrink: 0,
			display: 'inline-flex',
			alignItems: 'center',
			position: 'relative',
			fontSize: '14px',
			// backgroundColor: 'white',
			// borderRadius: '50px',
			// padding: '4px 12px',
			// border: '1px solid rgba(0, 0, 0, 0.1)',
			margin: 'auto auto 20px auto',
		},
		stepperTotal: {
			fontWeight: '600',
		},
		stepperDivider: {
			fontSize: '12px',
			margin: '0 6px',
		},
		modalHeader: {
			display: 'flex',
			width: '100%',
			borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
			padding: '12px',
			fontSize: '18px',
		},
		modalBody: {
			padding: '40px',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			[theme.breakpoints.down('md')]: {
				padding: '20px',
			},

		}
	})
);

export const CustomButton = styled(Button)({
	textTransform: 'none',
});
